
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";

import DateRangePicker from "@/components/form-components/DateRangePicker.vue";
import ImageViewer from "@/components/viewers/Image.vue";

export default Vue.extend({
  name: "Audits",

  components: { DateRangePicker, ImageViewer },

  mixins: [tablePagination, tableFilters],

  data: () => ({
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    selects: {
      users: [] as Array<SelectComponentInterface>
    } as any,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    loading: false as boolean,
    compareDialog: false as boolean,
    selectedItem: null as any
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 20
        }
      };
      const response = await API.audits().getData(params);
      const users = await API.user().getList();

      next(vm => {
        vm.selects.users = users;
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.audits().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });
        this.selects.users = await this.$API.user().getList();

        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    setServerResponse({ items, headers }: any) {
      this.headers = [
        ...headers,
        {
          text: "",
          value: "actions",
          align: "center",
          width: "30px",
          sortable: false
        }
      ];
      this.items = items.data;
      this.totalItems = items.total;
    },
    getRows(): Array<any> {
      const { new_values, old_values } = this.selectedItem.details;

      if (!old_values) {
        return Object.keys(new_values);
      }

      if (!new_values) {
        return Object.keys(old_values);
      }

      return Object.keys(new_values);
    },
    openCompareDialog(item: any) {
      this.selectedItem = item;
      this.compareDialog = true;
    }
  }
});
